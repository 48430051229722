import {animation} from './animation';
import {element} from './element';
import {appEvents} from '../app-events';

import {dom} from 'core-utils';

var __, exports;

__ = {};
exports = {
	__
};
__.layers = {};

/**
* @param {string} type - layer type e.g. 'cms', 'conflict-layer'
* @param {string} html - layer content as html string
* @param {Function} onCloseRequest - callback function for on close the layer
* @returns {Object} - layer object
*/
__.Layer = function (type, html, onCloseRequest) {
	this.type = type;
	this.html = html;
	this.element = element.create(html);
	this.onCloseRequest = onCloseRequest;

	return this;
};

/**
 * @returns {Promise<undefined>} - promise fulfilled if all animations and dom manipulations are over
*/
__.Layer.prototype.close = function () {
	return __.closeLayer(this);
};

/**
 * @param {string} newContent - layer content as html string
 */
__.Layer.prototype.update = function (newContent) {
	this.element = element.create(newContent);
	this.html = newContent;
};

/**
 * @returns {HTMLElement} - layer content
*/
__.Layer.prototype.getElement = function () {
	return this.element;
};

/**
 * destructor
 * resets properties
 */
__.Layer.prototype.remove = function () {
	delete __.layers[this.type];
	this.element = null;
	this.onCloseRequest = null;
	this.html = null;
	this.type = null;
};

/**
 * @param {string} type - layer type
 * @returns {boolean} - has active layer of given type
*/
__.hasActiveLayer = function (type) {
	return !!__.layers[type];
};

/**
 * @returns {number} - number of currently open layers
 */
__.nrOfLayers = function () {
	let count = 0,
		layer;

	for (layer in __.layers) {
		if (Object.prototype.hasOwnProperty.call(__.layers, layer)) {
			count++;
		}
	}

	return count;
};

__.initialize = function () {
	const delegate = dom.getEventDelegate('body');

	delegate.off('click', '.axs-layer-wrapper', exports.wrapperClickHandler);
	delegate.on('click', '.axs-layer-wrapper', exports.wrapperClickHandler);
};

/**
 * @param {string} type - The layer type e.g. 'cms', 'conflict-layer'
 * @param {string} html - layer content
 * @param {Function} onCloseRequest - on close callback
 * third party attempts to close this layer (by calling core/layer/base:requestCloseall()).
 * @returns {Promise<Object|Error>} - new created/updated layer OR Error
 */
exports.open = function (type, html, onCloseRequest) {
	let layer;

	if (!type) {
		return Promise.reject(new Error('type not set.'));
	}

	if (!html) {
		return Promise.reject(new Error('html not set.'));
	}

	if (!__.hasActiveLayer(type)) {
		// no layer of same type exist --> create new layer
		if (typeof onCloseRequest !== 'function') {
			return Promise.reject(new Error('onCloseRequest has to be a function.'));
		}
		else {
			layer = new __.Layer(type, html, onCloseRequest);
			__.layers[type] = layer;
			return __.openLayer(layer);
		}
	}
	else {
		if ((type === 'cms' || type === 'conflict-layer') && __.hasActiveLayer(type)) {
			// update only if type is "cms" or "conflict-layer"
			layer = new __.Layer(type, html, onCloseRequest);
			return __.updateLayer(layer, html);
		}
		else {
			// other layer types do not allow duplicates to be opened
			return Promise.reject(new Error('Duplicate layer type. ' + type));
		}
	}
};

exports.requestCloseall = function () {
	let type;

	for (type in __.layers) {
		if (Object.prototype.hasOwnProperty.call(__.layers, type)) {
			__.requestCloseLayer(type);
		}
	}
};

/**
 * @param {string} type - type of layer e.g. 'cms'
 */
__.requestCloseLayer = function (type) {
	const layer = __.layers[type];

	if (typeof layer.onCloseRequest === 'function') {
		/* if callback is given --> execute on close callback */
		layer.onCloseRequest();
	}
	else {
		__.closeLayer(layer);
	}
};

/**
 * handles communication with the animation module
 * @param {Object} layer_ - Layer instance
 * @returns {Promise<Object|Error>} - layer to open (with animation)
 */
__.openLayer = function (layer_) {
	let promise = animation.open(layer_.element, layer_);

	return promise.then(
		(layer) => {
			__.dispatchEvent(appEvents.LAYER_LOADED, {
				'element': layer.element
			});
			// Event for tracking 4.0
			__.dispatchCustomEvent('LAYER_LOADED');

			return layer;
		},
		(err) => {
			throw err;
		});
};

/**
 * @param {string} eventName_ - event name
 * @param {Object} payLoad_ - event data
 */
__.dispatchEvent = function (eventName_, payLoad_) {
	__.globalEventBus.emit(eventName_, payLoad_);
};

/**
 * @param {string} eventName_ - name of custom event, e.g. 'LAYER_LOADED'
 */
__.dispatchCustomEvent = function (eventName_) {
	const customEvent = new CustomEvent(eventName_);

	document.dispatchEvent(customEvent);
};

/**
 * @param {Object} layer_ - layer instance to close
 * @returns {Promise<undefined>} - Promise is fullfilled when layer fade out animation is over + layer wrapper html is removed
 */
__.closeLayer = function (layer_) {
	const promise = animation.close(layer_.element, layer_);

	__.layers[layer_.type] = null;

	return promise.then(
		(layer) => {
			layer.remove();
			__.dispatchEvent(appEvents.LAYER_CLOSED, {'element': layer_.element});
			// Event for tracking 4.0
			__.dispatchCustomEvent('LAYER_CLOSED');
		},
		(err) => {
			throw err;
		}
	).then(animation.toggleShader);
};

/**
 * update an existing layer with content of the same type
 * @param {Object} layer_ - layer object
 * @param {HTMLElement} newContent_ - new html content
 * @returns {Promise<Object|Error>} - new layer
 */
__.updateLayer = function (layer_, newContent_) {
	layer_.update(newContent_);
	const promise = animation.replace(layer_.getElement(), layer_);

	return promise.then(
		(layer) => {
			__.dispatchEvent(appEvents.LAYER_LOADED, {
				'element': layer.getElement()
			});

			// Event for tracking 4.0
			__.dispatchCustomEvent('LAYER_LOADED');
			return layer;
		},
		(err) => {
			throw err;
		}
	);
};

/**
 * Checks if a wrapper click has been outside the layer element
 * (if a layer is currently open) and has to be treated as a
 * request to close layers.
 * @param {Event} event - the click event
 */
exports.wrapperClickHandler = function (event) {
	if (__.nrOfLayers() > 0) {
		if (__.isShaderClicked(event)) {
			exports.requestCloseall();
		}
	}
};

/**
 * @param {Event} e - click event
 * @returns {boolean} - was click on shader (true) or not (false)
*/
__.isShaderClicked = function (e) {
	if (e) {
		return dom.isElementOutsideOfElementWithSelector(e.target, '.axs-layer-inner') && e.target.classList.contains('axs-layer-wrapper');
	}
	else {
		return false;
	}
};

/**
 * @param {EventBus} globalEventBus - global event bus
 * @returns {Promise<string|undefined>} - module is initialized
 */
exports.initialize = function (globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.initialize();
		resolve('api.js');
	});
};

export {exports as api};
