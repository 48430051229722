const exports = `
<div class="axs-module">
	<div class="axs-layer-dialogue-content axs-inner-content">
		{{var errorHeadline = window.i18n['error-layer.headline']; }}
		<h1 class="axs-h2">{{=errorHeadline}}</h1>
		{{ var defaultErrorMessage = window.i18n['error-layer.unknown-error'] || 'Es ist ein unbekannter Fehler aufgetreten'; }}
		<p class="axs-c3 axs-error axs-error-layer-message" style="float:none;" data-defaultErrorMessage="{{=defaultErrorMessage}}"> {{=it.errorMessage}} </p> <!-- TODO: i18n -->
		<a class="axs-b1 axs-j-ok-btn" style="float:right;" href="#">
			{{ var ok = window.i18n['ok-label'] || 'OK'; }}
			<span>{{=ok}}</span>
		</a>
	</div>
</div>
`;

export {exports as errorTemplate};
