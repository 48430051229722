
import {appEvents} from './application/app-events';
import {router} from './application/router';
import {scroll} from './application/scroll';
import {footnoteScroll} from './application/footnote-scroll';
import * as scrollToTop from './application/scroll-to-top';
import {signal} from './application/signal';

import {animation as layerAnimation} from './application/layer/animation';
import {api as layerApi} from './application/layer/api';
import {cms as layerCms} from './application/layer/cms';
import {element as layerElement} from './application/layer/element';

/**
 * global internationalization key|value storage
 */
window.i18n = window.i18n || {};

/**
 * modules to be initialized with event emitter
 */
const toBeInitialized = [
	footnoteScroll,
	layerAnimation,
	layerApi,
	router, // out of alphabetic order, because we need the initialized router in layerCms
	layerCms
];

/**
 * initialize modules and inject signal dependency
 */
const eventEmitter = signal.getEmitter();

toBeInitialized.forEach(module => {
	try {
		module.initialize(eventEmitter);
	}
	catch (err) {
		console.error(err);
	}
});

export {
	appEvents,
	footnoteScroll,
	layerAnimation,
	layerApi,
	layerCms,
	layerElement,
	router,
	scroll,
	signal,
	scrollToTop
};
