import {eventEmitter as EventEmitter} from 'core-vendor';

/**
 * The core signal module.
 * @exports core/signal
 */
var __ = {}, exports = {__};

__.defaultConfig = {
	wildcard: true,
	newListener: false,
	maxListeners: 0
};

EventEmitter.prototype.one = EventEmitter.prototype.once;
EventEmitter.prototype.trigger = EventEmitter.prototype.emit;

__.defaultEmitter = new EventEmitter(__.defaultConfig);

/*
* The default emitter gets an alias for `emit` called `trigger`
* for API compatibility with jQuery events.
*/
__.defaultEmitter.trigger = function() {
	__.defaultEmitter.emit.apply(this, arguments);
};

__.emitters = {
	'default': __.defaultEmitter
};

/**
 * Returns an EventEmitter2 instance.
 * @param {string} name -- The name of the emitter (optional).
 * @returns {EventEmitter2} object.
 */
exports.getEmitter = function(name) {
	// Return the default emitter if no name is given.
	if (typeof name === 'undefined') {
		return __.emitters.default;
	}

	// Create new emitter for name if none exists yet.
	__.emitters[name] = __.emitters[name] || new EventEmitter(__.defaultConfig);
	__.emitters[name].setMaxListeners(0);
	return __.emitters[name];
};

export {exports as signal};
