import {api as layerAPI} from './api';
import {errorTemplate as ERROR_LAYER_TPL} from './error-template';
import {appEvents} from '../app-events';
import {router} from '../router';

import {dom, template} from 'core-utils';

var __, exports;

// internals
__ = {};
// api
exports = {
	__
};
/*
	* DOM selectors and IDs.
	*/
__.LAYER_TPL_ID = 'axs-id-default-data-layer-tpl';
__.LAYER_CLOSE_LINK_SELECTOR = '.axs-button-close';
// Default layer type
__.defaultLayerType = 'cms';
// Storage for the template sting
__.templateStr = null;
// The current layer object
__.layer = null;
// The data for the next layer;
// [data, attrs, replacedAttrs]
__.nextLayerData = null;
__.openLayersSinceLastClose = 0;
__.backButtonEl = null;
__.sMarkerXHR = '.headless';

/*
	* Looks up the template string in the DOM, stores and returns it.
	*/
__.getTemplateStr = function(attrs) {
	var templateEl, templateSelector;

	templateSelector = '#' + __.LAYER_TPL_ID;

	if (!!attrs && !!attrs['data-layer-tpl']) {
		templateSelector = '#axs-id-' + attrs['data-layer-tpl'] + '-layer-tpl';
	}

	templateEl = dom.getElementsArray(templateSelector);

	if (templateEl.length === 0) {
		return '';
	}

	return templateEl[0].innerHTML;
};

/*
* Subscribes to layerOpen and layerClose
*/
__.addEvents = function() {
	__.globalEventBus.on(appEvents.LAYER_OPEN, exports.handleLayerOpen);
	__.globalEventBus.on(appEvents.LAYER_CLOSE, exports.handleLayerClose);
	/*
		* register .axs-layer-link clicks
		*/
	router.register('.axs-layer-link', 'layer');
	router.register('#axs-id-layer-tab-navigation a', 'layer');
	router.register('.axs-layer .axs-button-close', 'layer', 'close');
	router.register('.axs-layer .axs-button-cancel', 'layer', 'close');
	router.register('.axs-layerClose', 'layer', 'close');
};

__.initialize = function() {
	__.addEvents();
};

/*
	* handle errors during layer open/loading
	*/
__.handleError = function(err) {
	var tplStr, closeFunction, delegate;

	tplStr = template.render(ERROR_LAYER_TPL, {
		errorMessage: window.i18n['error-layer.unknown-error'] || 'Es ist ein unbekannter Fehler aufgetreten'
	});
	console.warn(err);
	layerAPI.open('error', tplStr).then((layer) => {
		closeFunction = function(e) {
			if (e.preventDefault) {
				e.preventDefault();
			}

			layer.close();
		};

		delegate=dom.getEventDelegate('body');
		delegate.off('click', '.axs-error-layer .axs-j-ok-btn', closeFunction);
		delegate.on('click', '.axs-error-layer .axs-j-ok-btn', closeFunction);
	}, (error) => {
		console.error(error);
	});
	history.back();
};

/**
 * create layer conetnt from loaded htmlstring
 * @param {string} content_ - html string
 * @param {string} url_ - url string
 * @returns {Promise} Promise
 */
__.createLayerContent = function(content_, url_) {
	var returnPromise;

	if (content_.indexOf('<html') !== -1) {
		returnPromise = Promise.reject('content is not headless');
	}

	if (!__.layer || __.layer.type === 'cms') {
		return layerAPI.open('cms', content_, exports.handleCloseRequest).then((layer) => {
			__.layer = layer;
			__.layer.url = url_;
			__.controlBackButton(__.layer);
			returnPromise = Promise.resolve(__.layer);
		});
	}
	else {
		returnPromise = Promise.reject('could not Open Layer');
	}

	return returnPromise.catch((err) => {
		console.error('could not Open Layer: ', err);
	});
};

/**
 * fetch remote content
 * @param  {string} sKey_	   - Convention: URI
 * @returns {Promise} - Promise
 */
__.loadRemoteContent = function(sKey_) {
	const url = __.rewriteUrl(sKey_);

	return fetch(url, {
		method: 'get'
	}).then((response_) => {
		return response_.text();
	}).catch((error_) => {
		console.error('content loading for layer failed:', error_);
	});
};

/**
 * Adds the required XHR-marker (CQ5 / akamai)
 * @param  {string} sUrl_ - Url
 * @returns {string} returns new url
 */
__.rewriteUrl = function(sUrl_) {
	var _ext = '.' + sUrl_.split('#')[0].split('?')[0].split('.').pop();
	var newUrl = sUrl_.split(_ext).join(__.sMarkerXHR + _ext);

	return newUrl;
};

__.controlBackButton = function(layer) {
	if (__.openLayersSinceLastClose > 1) {
		dom.getElement('.axs-button-back', layer.getElement()).classList.remove('axs-hidden');
	}
};

/**
 * Handles a layer.open event
 * @param {Object} e - The event object.
 */
exports.handleLayerOpen = function(e) {
	var {data} = e,
		url;

	if (!!e && typeof e.preventDefault === 'function') {
		e.preventDefault();
	}

	__.openLayersSinceLastClose += 1;
	url = data.layer;
	__.loadRemoteContent(url).then((content) => {
		return __.createLayerContent(content, url);
	}).catch(__.handleError);
};

/**
 * Handles a 'layer.close' event
 * @param {Object} e - The event object.
 */
exports.handleLayerClose = function(e) {
	if (!!e && typeof e.preventDefault === 'function') {
		e.preventDefault();
	}

	__.openLayersSinceLastClose = 0;

	if (!__.layer) {
		return false;
	}

	__.layer.close().then(() => {
		__.layer = undefined;
	}, (err) => {
		console.error(err);
	});
};

exports.handleCloseRequest = function() {
	router.close('layer', __.layer.url);
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - tbd
 * @returns {Promise} returns Promise
 */
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.initialize();
		resolve('cms.js');
	});
};

export {exports as cms};
