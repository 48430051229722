(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("core-utils"), require("core-vendor"));
	else if(typeof define === 'function' && define.amd)
		define("application-bundle", ["core-utils", "core-vendor"], factory);
	else if(typeof exports === 'object')
		exports["application-bundle"] = factory(require("core-utils"), require("core-vendor"));
	else
		root["application-bundle"] = factory(root["core-utils"], root["core-vendor"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__1139__, __WEBPACK_EXTERNAL_MODULE__1848__) {
return 