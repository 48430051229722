import {scroll} from './scroll';
import {dom} from 'core-utils';

export default class ScrollToTop extends HTMLElement {

	constructor() {
		super();
		this.scrollUp = this._scrollUp.bind(this);
		this.scrollDown = this._scrollDown.bind(this);
	}

	connectedCallback() {
		this._createHTML();
		this._addEvents();
	}

	_createHTML() {
		this.classList.add('axs-scroll-to-top');

		this.innerHTML = `<button class="axs-scroll-to-top__button">
			<svg class="axs-scroll-to-top_arrow-up" focusable="false" tabindex="-1" aria-hidden="true">
				<use xlink:href="${SETUPS.get('DocrootJs')}/../includes/core-ci/assets/icons/svg/arrow-up-large.svg#nm-icon-arrow-up-large"></use>
			</svg>
			<span class="axs-visually-hidden">Scroll top</span>
		</button>`;
	}

	_addEvents() {
		this.addEventListener('click', this._scrollToTop);

		scroll.register('body', {
			notifyWhen: scroll.NOTIFY.ONSCROLL,
			mode: scroll.MODE.SCROLLUP,
			eventName: 'scrollup'
		}, this.scrollUp);

		scroll.register('body', {
			notifyWhen: scroll.NOTIFY.ONSCROLL,
			mode: scroll.MODE.SCROLLDOWN,
			eventName: 'scrolldown'
		}, this.scrollDown);
	}

	_scrollToTop() {
		dom.scrollTo(0, {
			duration: 300,
			easing: 'easeOutExpo'
		});
	}

	_scrollUp() {
		if (window.pageYOffset < 20) {
			this.isActive = false;
		}
		else {
			this.isActive = true;
		}
	}

	_scrollDown() {
		this.isActive = false;
	}

	set isActive(value_) {
		this.setAttribute('is-active', value_);
	}
}

window.customElements.define('audi-scroll-to-top', ScrollToTop);

document.body.appendChild(new ScrollToTop());
