import {signal} from './signal';

var __ = {},
	api = {__},
	initialized = false,
	working = false,
	viewport = {
		top: 0,
		bottom: 0
	},
	configured = [],
	registered = [
		[],
		[]
	],
	lastWindowScrollPos = [0, 0],
	WHERE = {
		'ABOVE': 1,
		'IN': 2,
		'BELOW': 3
	},
	NOTIFY = {
		'ONSCROLL': 0,
		'AFTERSCROLL': 1
	},
	MODE = {
		'INOUT': 0,
		'COVER': 1,
		'SCROLLUP': 2,
		'SCROLLDOWN': 3
	},
	// eventEmitter,
	scrollEndTimer,
	defaults = {
		notifyWhen: NOTIFY.ONSCROLL,
		eventName: '',
		mode: MODE.INOUT
	};

__.updateViewportData = function() {
	viewport = {
		top: 0,
		bottom: (window.innerHeight || document.documentElement.clientHeight)
	};
};

__.pageLoadHandler = function(e, payload) {
	var i, l;

	registered = [
		[],
		[]
	];
	signal.getEmitter().removeAllListeners('element.scroll');

	for (i = 0, l = configured.length; i < l; i++) {
		__.register(configured[i].selector, configured[i].options, configured[i].callback, payload.domElement[0], true);
	}
};

__.register = function(selector, options, callback, container, reInit) { // eslint-disable-line max-params, max-statements
	var elements,
		container_ = container,
		notifyWhen,
		eventName,
		mode,
		list,
		i, l,
		el,
		data,
		where;

	if (typeof reInit === 'undefined' || !reInit) {
		configured.push({
			selector,
			options,
			callback
		});
	}

	if (!container_) {
		container_ = document;
	}

	elements = container_.querySelectorAll(selector);

	if (!!elements.length) {
		if (!initialized) {
			window.addEventListener('scroll', __.scrollHandler);
			window.addEventListener('scroll', __.scrollEndHandler);
			window.addEventListener('resize', __.scrollHandler);
			window.addEventListener('resize', __.scrollEndHandler);
			window.addEventListener('resize', __.updateViewportData);
			__.updateViewportData();
			initialized = true;
		}

		notifyWhen = options.notifyWhen || defaults.notifyWhen;
		eventName = options.eventName || defaults.eventName;
		mode = options.mode || defaults.mode;
		list = [];
		signal.getEmitter().addListener('element.scroll.' + eventName, callback);

		for (i = 0, l = elements.length; i < l; i++) {
			el = elements[i];
			data = {};
			where = __.getElementsState(el, mode, elements);
			data = {
				element: el,
				selector,
				eventName,
				mode,
				where,
				was: where,
				elements
			};
			signal.getEmitter().trigger('element.scroll.' + data.eventName, data);
			list.push(data);
		}

		if (!!list) {
			registered[notifyWhen].push(list);
		}
	}
};

__.getElementsState = function(el, mode, elements) {
	var where = __.getElementState(el),
		i
		// max,
		// elCoverage,
		// rect
		;

	if (mode === MODE.COVER && where === WHERE.IN) {
		// if we have an element with 100% coverage before the current element, we are BELOW, otherwise we are really IN
		for (i = 0; i < elements.length; i++) {
			if (elements[i] === el) {
				break;
			}

			if (__.getElementCoverage(elements[i]) === 100) {
				return WHERE.BELOW;
			}
		}
	}

	return where;
};

__.getElementState = function(el) {
	var rect = el.getBoundingClientRect();

	if (
		(rect.top >= viewport.top && rect.top < viewport.bottom) || /* top in viewport */ (rect.bottom >= viewport.top && rect.bottom < viewport.bottom) || /* bottom in viewport */ (rect.top < viewport.top && rect.bottom > viewport.top) /* top out of viewport but bottom in or below viewport */) {
		return WHERE.IN;
	}
	else if (rect.top < viewport.top) {
		return WHERE.ABOVE;
	}
	else {
		return WHERE.BELOW;
	}
};

__.getElementCoverage = function(el) {
	if (__.getElementState(el) !== WHERE.IN) {
		return 0;
	}

	const rect = el.getBoundingClientRect();

	if (rect.top >= viewport.top && rect.bottom <= viewport.bottom) {
		return 100;
	}

	let {top, bottom} = rect;

	if (top < 0) {
		top = 0;
	}

	if (bottom > viewport.bottom) {
		({bottom} = viewport);
	}

	return ((bottom - top) / (viewport.bottom - viewport.top)) * 100;
};

__.checkOnScroll = function() {
	__.checkChangedElements(NOTIFY.ONSCROLL);
};

__.checkAfterScroll = function() {
	__.checkChangedElements(NOTIFY.AFTERSCROLL);
};

__.checkChangedElements = function(notifyWhen) { // eslint-disable-line max-statements
	var where, windowScrollPos = pageYOffset,
		i, l, j;

	working = false;

	if (registered[notifyWhen].length > 0) {
		for (i = 0, l = registered[notifyWhen].length; i < l; i++) {
			for (j in registered[notifyWhen][i]) {
				if (document.body.contains(registered[notifyWhen][i][j].element)) { // only check elements still in the dom
					where = __.getElementsState(registered[notifyWhen][i][j].element, registered[notifyWhen][i][j].mode, registered[notifyWhen][i][j].elements);
					registered[notifyWhen][i][j].was = registered[notifyWhen][i][j].where;
					registered[notifyWhen][i][j].where = where;

					if (registered[notifyWhen][i][j].mode === MODE.SCROLLDOWN) { // eslint-disable-line max-depth
						if (windowScrollPos > lastWindowScrollPos[notifyWhen]) { // eslint-disable-line max-depth
							signal.getEmitter().trigger('element.scroll.' + registered[notifyWhen][i][j].eventName, registered[notifyWhen][i][j]);
						}
					}
					else if (registered[notifyWhen][i][j].mode === MODE.SCROLLUP) { // eslint-disable-line max-depth
						if (windowScrollPos < lastWindowScrollPos[notifyWhen]) { // eslint-disable-line max-depth
							signal.getEmitter().trigger('element.scroll.' + registered[notifyWhen][i][j].eventName, registered[notifyWhen][i][j]);
						}
					}
					else if (registered[notifyWhen][i][j].was !== registered[notifyWhen][i][j].where) {
						signal.getEmitter().trigger('element.scroll.' + registered[notifyWhen][i][j].eventName, registered[notifyWhen][i][j]);
					}
				}
			}
		}
	}

	lastWindowScrollPos[notifyWhen] = windowScrollPos;
};

__.scrollHandler = function() {
	if (!working) {
		working = true;
		window.requestAnimationFrame(__.checkOnScroll);
	}
};

__.scrollEndHandler = function() {
	clearTimeout(scrollEndTimer);
	scrollEndTimer = window.setTimeout(() => {
		window.requestAnimationFrame(__.checkAfterScroll);
	}, 250);
};

api.initialize = function(globalEventBus) {
	__.globalEventBus = globalEventBus;
	// TODO: gibt noch keinen PAGE_LOADED in EVENTS, ist das in AXS notwendig? gibt es ajax content der geladen wird?
	// __.globalEventBus.on(EVENTS.PAGE_LOADED, __.pageLoadHandler);
	return Promise.resolve('scroll.js');
};

api.register = __.register;
api.unregister = __.unregister;
api.WHERE = WHERE;
api.NOTIFY = NOTIFY;
api.MODE = MODE;

export {api as scroll};
