import {dom} from 'core-utils';

var __ = {},
	exports = {__};

__.shader = null;
__.LAYER_SELECTOR = '.axs-layer';
__.VISIBLE_CLASS_NAME = 'axs-layer-visible';
__.BODY_LAYER_OPENED_CLASS = 'axs-layer-opened';
__.SHADER_VISIBLE_CLASS_NAME = 'axs-layer-shader-visible';

/**
 * To be called before the first layer is opened.
 * @returns {Promise<undefined>} - shader is shown (animations are over)
 */
exports.showShader = function() {
	return new Promise(resolve => {
		const bd = dom.getElement('body');

		bd.classList.remove(__.BODY_LAYER_OPENED_CLASS);
		bd.classList.add(__.BODY_LAYER_OPENED_CLASS);

		__.wait(20).then(() => {
			__.shader.classList.remove(__.SHADER_VISIBLE_CLASS_NAME);
			__.shader.classList.add(__.SHADER_VISIBLE_CLASS_NAME);
			__.shader.classList.remove('show');
			__.shader.classList.add('show');
		}).then(() => {
			__.wait(280).then(resolve);
		});
	});
};

/**
 * @param {number} delay_ - delay in milliseconds
 * @returns {Promise<undefined>} - timeout is over
 */
__.wait = function(delay_) {
	return new Promise(resolve => {
		setTimeout(resolve, delay_);
	});
};

/**
 * To be called after the last layer has been closed.
 * @returns {Promise<HTMLElement>} - shader to be hidden
*/
exports.hideShader = function() {
	return new Promise(resolve => {
		__.shader.classList.remove(__.SHADER_VISIBLE_CLASS_NAME);
		__.shader.classList.remove('show');
		dom.getElement('body').classList.remove(__.BODY_LAYER_OPENED_CLASS);
		resolve(__.shader);
	});
};

/**
 * @param {string} layerType_ - type of layer e.g. 'cms','conflict' ...
 * @returns {HTMLElement|undefined} - layer wrapper of layer with given type or undefined
 */
__.findCurrentLayerOfType = function(layerType_) {
	const layer = dom.getElementsArray(__.LAYER_SELECTOR + '[data-layer-type=\'' + layerType_ + '\']');

	return dom.closest('.nm-layer-wrapper', layer) || null;
};

/**
 * Starts an animation that opens a layer.
 * @param {string} element_ - layer content
 * @param {Object} layerObj_ - layer instance to open
 * @returns {Promise<Object|Error>} - opened layer or error
 */
exports.open = function(element_, layerObj_) {
	let promise;

	if (!element_ || !layerObj_) {
		promise = Promise.reject(new Error('\'animation.open\': missing param ' + (!element_) ? '\'element_\'' : '\'layerObj_\''));
	}
	else {
		const layerEl = __.createLayerWrapper(element_);

		document.body.appendChild(layerEl);

		const isFirstLayer = dom.getElementsArray(__.LAYER_SELECTOR).length === 1;

		if (isFirstLayer) {
			// show shader first
			promise = exports.showShader().then(() => {
				return __.openLayer(layerEl, layerObj_);
			});
		}
		else {
			promise = __.openLayer(layerEl, layerObj_);
		}
	}

	return promise;
};

/**
 * @param {HTMLElement} content_ - layer content
 * @returns {HTMLElement} - layer wrapper
*/
__.createLayerWrapper = function (content_) {
	const containerWrapper = document.createElement('div');
	const containerLayer = document.createElement('div');
	const containerCloseButton = document.createElement('div');
	const containerInner = document.createElement('div');

	containerInner.className = 'axs-layer-inner';
	containerWrapper.className = 'axs-layer-wrapper';
	containerLayer.className = 'axs-layer';
	containerCloseButton.className = 'axs-button-close axs-b-icon axs-icon-close';
	containerCloseButton.innerHtml = '<span>X</span>';
	containerWrapper.appendChild(containerLayer);
	containerLayer.appendChild(containerCloseButton);
	containerLayer.appendChild(containerInner);
	containerInner.appendChild(content_);

	return containerWrapper;
};

/**
 * @param {HTMLElement} layerEl_ - layer
 * @param {Object} layer_ - layer object
 * @returns {Promise<Object>} - opened layer
 */
__.openLayer = function(layerEl_, layer_) {
	return new Promise(resolve => {
		// resolve layer before fadeIn CSS transition starts
		resolve(layer_);
		// add class, with a short delay.
		__.wait(20).then(() => {
			const layer = dom.getElement(__.LAYER_SELECTOR, layerEl_);

			layer.classList.add(__.VISIBLE_CLASS_NAME);
			layer.setAttribute('data-layer-type', layer_.type);
		});
		// css layer transition is .5s, remove class after little more than 500ms.
		__.wait(530).then(() => {
			dom.getElement('body').classList.add('axs-' + layer_.type + '-layer-opened');
		});
	});
};

/**
 * Starts an animation that closes a layer.
 * @param {HTMLElement} element_ - layer content
 * @param {Object} layerObj_ - layer object
 * @returns {Promise<Object|Error>} - closed layer or error
 */
exports.close = function(element_, layerObj_) {
	let promise;

	if (!element_ || !layerObj_) {
		promise = Promise.reject(new Error('\'animation.close\': missing param ' + (!element_) ? '\'element_\'' : '\'layerObj_\''));
	}
	else {
		// hide layer
		const layer = dom.getElement(__.LAYER_SELECTOR, element_);

		layer.classList.remove(__.VISIBLE_CLASS_NAME);
		layer.removeAttribute('data-layer-type');

		// wait for CSS fadeOut trasition 0.5s
		promise = __.wait(500).then(() => {
			// remove layer content
			if (!element_) {
				return Promise.reject(new Error('Element to be removed is missing!'));
			}

			element_.parentNode.removeChild(element_);

			// remove layer wrapper
			const layerWrapper = dom.getElement('.axs-layer-wrapper');

			layerWrapper.parentNode.removeChild(layerWrapper);
			dom.getElement('body').classList.remove('axs-' + layerObj_.type + '-layer-opened');

			return Promise.resolve(layerObj_);
		});
	}

	return promise;
};

exports.toggleShader = function() {
	if (__.isShaderNotNeeded()) {
		exports.hideShader();
	}
};

/**
 * @returns {boolean} - shader can be removed (true) or not(false)
 */
__.isShaderNotNeeded = function() {
	return (__.getVisibleLayers().length === 0);
};

/**
 * @returns {boolean} - the current visible layer is the last one (true) or not (false)
 */
__.isLastVisibleLayer = function() {
	return (__.getVisibleLayers().length === 1);
};

/**
 * @returns {Array<HTMLElement>} - visible layers
 */
__.getVisibleLayers = function() {
	return dom.getElementsArray(__.LAYER_SELECTOR + ' ' + __.VISIBLE_CLASS_NAME);
};

/**
 * Starts an animation that replaces a layer.
 * @param {string} element_ - layer content html
 * @param {Object} layerObj_ - layer object
 * @returns {Promise<Object|Error>} - new layer or error
 */
exports.replace = function(element_, layerObj_) {
	let promise;

	if (!element_ || !layerObj_) {
		promise = Promise.reject(new Error('\'animation.replace\': missing param ' + (!element_) ? '\'element_\'' : '\'layerObj_\''));
	}
	else {
		promise = new Promise((resolve, reject) => {
			const layerType = layerObj_.type;
			const oldLayer = __.findCurrentLayerOfType(layerType);

			if (!oldLayer) {
				reject(new Error('noLayer of type ' + layerObj_.type));
			}
			else {
				// fadeOut old layer
				oldLayer.classlists.remove(__.VISIBLE_CLASS_NAME);
				const layerEl = dom.getElement('body').appendChild(element_);

				// add class, with a short delay (longer for replace because we run a fadeout on the old layer).
				__.wait(500).then(() => {
					const el = dom.getElement(__.LAYER_SELECTOR, layerEl);

					el.classList.add(__.VISIBLE_CLASS_NAME);
					el.setAttribute('data-layer-type', layerObj_.type);
				});
				// css layer transition is .5s, so resolve after a little more than 500ms.
				dom.getElement('body').classList.add('axs-' + layerObj_.type + '-layer-opened');
				__.wait(530).then(() => {
					oldLayer.parentNode.removeChild(oldLayer);
					resolve(layerObj_);
				});
			}
		});
	}

	return promise;
};

exports.init = function() {
	if (dom.getElementsArray('.axs-layer-shader').length === 0) {
		const container = document.createElement('div');

		container.className = 'axs-layer-shader';
		dom.getElement('body').appendChild(container);
		__.shader = dom.getElement('.axs-layer-shader');
	}
};

/**
 * @param {Object} globalEventBus - event bus
 * @returns {Promise} - script name
 */
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		exports.init();
		resolve('animation.js');
	});
};

export {exports as animation};
