const exports = {
	/**
	 * Configuration Events
	 */
	'VERSION_LOAD_COMPLETE': 'version.load.complete',
	// Dom is ready and all required base config params (e.g. 'nemo.url.dpu') are avialable
	'APP_READY': 'app.ready',

	'APP_INITIALIZED': 'app.initialized',
	/**
	 * Layer Events
	 */
	'LAYER_OPEN': 'layer.open',

	'LAYER_CLOSE': 'layer.close',

	'LAYER_INSERTED': 'layer.inserted',

	'LAYER_LOADED': 'layer.loaded',

	'LAYER_STATIC_OPEN': 'layer.static.open',

	'LAYER_BEFORE_CLOSE_ALL': 'layer.before.closeall',

	'LAYER_BEFORE_CLOSE': 'layer.before.close',

	'LAYER_CLOSED': 'layer.closed',

	'MENUFLYOUT_OPEN': 'menuflyout.open',

	'MENUFLYOUT_CLOSE': 'menuflyout.close',

	/**
	 * Page Events
	 */
	'PAGE_NAV': 'page.nav',

	'PAGE_OPEN': 'page.open',

	'PAGE_READY': 'page.ready',

	'PAGE_INSERTED': 'page.inserted',

	'PAGE_LOADED': 'page.loaded',

	'PAGE_ANIMATION_START': 'page.animation.start',

	'PAGE_ANIMATION_COMPLETE': 'page.animation.complete',


	/**
	 * Custom Events
	 */

	'ERROR': 'nemo.error',

	'CONTENT_RESIZE': 'content.resize'
};

export {exports as appEvents};
