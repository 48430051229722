import {dom} from 'core-utils';

const __ = {};
const exports = {__};

__.oDefaults = {};

/**
 * handles the jump-to-footnote event
 * @param {Event} event_ - jump-to-footnote event
 */
__.handleJumpToFootnote = function (event_) {
	const scrollToElement = event_.target.getAttribute('data-footnote'),
		$scrollElement = dom.getElement('[id="' + scrollToElement + '"]');
	let scrollToPosition;

	event_.preventDefault();

	if ((scrollToElement)) {
		if (!dom.isElement($scrollElement)) {
			console.warn('scroll to element ' + scrollToElement + 'failed, element not found.');
			return;
		}

		scrollToPosition = parseInt($scrollElement.getBoundingClientRect().top + window.pageYOffset, 10);

		dom.scrollTo(scrollToPosition, 660);
	}
};

/**
 * adds click events to footnote references
 */
__.addEvents = function () {
	dom.getEventDelegate('body').on('click', '.footnote.axs-j-scroll', __.handleJumpToFootnote);
};

__.initialize = function () {
	__.addEvents();
};

/**
 * public initialize method
 * @param {Object} globalEventBus_ - Event Bus
 * @returns {Promise<string>} Promise resolving on initialization
 */
exports.initialize = function (globalEventBus_) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus_;
		__.initialize();
		resolve('footnote-scroll.js');
	});
};

export {exports as footnoteScroll};
