import {dom} from 'core-utils';

var __, exports;

__ = {};
exports = {
	__
};
__.BASE_CLASS = 'axs-layer-wrapper';
__.loadedScripts = [];

exports.create = function(html) {
	var container, child;

	container = document.createElement('div');
	// container.className = __.BASE_CLASS;
	__.globalEval(html);
	container.innerHTML = html;
	// jQuery(container).html(html);
	// return child if it already has a class
	child = container.firstChild;

	if (!!child.className && child.className.split(' ').indexOf(__.BASE_CLASS) > -1) {
		return child;
	}

	return container;
};

exports.update = function(el, html) {
	__.globalEval(html);
	el.innerHTML = html;
	return true;
};

// Evaluates inline javascripts in a global context
__.globalEval = function(code_) {
	var // $code,
		$scripts,
		strictMode,
		codeContainer;
	// code = jQuery.trim(code);
	var code = code_.trim();

	// $code = jQuery(code);
	codeContainer = document.createElement('div');
	codeContainer.innerHTML = code;
	// $scripts = $code.find("script");
	$scripts = dom.getElementsArray('script', codeContainer);

	// no inline script tags?
	if (!$scripts.length) {
		return;
	}

	// TODO: revert text() to dom
	strictMode = -1; // ($scripts.text().indexOf("use strict") > -1);
	window.asyncLoadingJS = false;
	__.evalScripts($scripts, strictMode);
};

__.evalScripts = function($scripts, strictMode) { // eslint-disable-line max-statements
	var $script,
		item,
		itemSrc,
		script,
		head,
		indirect = eval; // eslint-disable-line no-eval

	while ($scripts.length) {
		$script = $scripts.splice(0, 1);
		item = $script[0];
		// If the code includes a valid, prologue position
		// strict mode pragma, execute code by injecting a
		// script tag into the document.
		// if (item.getAttribute('src')) {
		itemSrc = item.getAttribute('src');

		if (itemSrc) {
			// we have to load a script, so we really need to create a new script tag for it
			// WARNING: if the loaded script calls it's own callback we need to reset window.asyncLoadingJS there to continue loading
			if (!window.asyncLoadingJS) {
				if (__.loadedScripts.indexOf(itemSrc) < 0) {
					__.loadedScripts.push(itemSrc);
					window.asyncLoadingJS = true;

					fetch(itemSrc, {
						method: 'get',
						credentials: 'omit'
					}).then((response_) => {
						window.asyncLoadingJS = false;
						response_.text();
					}).catch((error_) => {
						console.error('content loading failed:', error_);
					});
				}
			}
			else {
				console.warn('defered eval of js. WARNING: if the loaded script calls it\'s own callback we need to reset window.asyncLoadingJS there to continue loading');
				setTimeout(() => { // eslint-disable-line no-loop-func
					$scripts.splice(0, 0, item);
					__.evalScripts($scripts, strictMode);
				}, 100);
			}
		}
		else if (strictMode) {
			script = document.createElement('script');
			script.text = item.text;
			// IE<9 Bugfix
			head = document.head || document.getElementsByTagName('head')[0];
			head.appendChild(script).parentNode.removeChild(script);
		}
		else {
			// Otherwise, avoid the DOM node creation, insertion
			// and removal by using an indirect global eval
			indirect(item);
		}
	}
};

export {exports as element};
