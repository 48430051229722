import {RouterState} from './router-state';

import {dom} from 'core-utils';

const __ = {
	state: null,
	scrollPos: undefined, // scroll pos before hash change (applies only for empty new hash)
	registered: [] // registered selectors and channels
};

/**
 * initializes the object, publishes changes already collected on startup, adds hashChange event listener
 */
__.initialize = function () {
	__.state = __.createState(location.pathname, location.hash);

	const initialNotify = () => {
		__.publishChanges(__.state.getAndResetChanges());
	};

	window.setTimeout(initialNotify, 100);

	window.addEventListener('hashchange', __.hashChangeHandler);
};

__.hashChangeHandler = () => {
	// reset the window's scroll position (based on the last hash update)
	if (__.scrollPos) {
		document.body.scrollTop = __.scrollPos;
	}

	__.state.update(location.hash);
	__.publishChanges(__.state.getAndResetChanges());
};

/**
 * construct new hash string from old hash and new key value pair
 * @param {string} oldHash_ - current / old hash string to be parsed
 * @param {string} keyName_ - in AXS usually 'layer'
 * @param {string} value_ - in our context the path of the layer content
 * @param {string} action_ - "open" or "close"
 * @returns {string} newly created hash string
 */
__.newHashString = function (oldHash_, keyName_, value_, action_ = 'open') {
	const hashObjects = __.state.parseHash(oldHash_);

	if (action_ === 'close' && hashObjects[keyName_] !== undefined) {
		delete hashObjects[keyName_];
	}
	else {
		hashObjects[keyName_] = value_;
	}

	const hashParts = [];

	for (let key in hashObjects) {
		if (!!hashObjects[key]) {
			hashParts.push(key + '=' + hashObjects[key]);
		}
	}

	return hashParts.join(RouterState.URL_PARAM_SEPARATOR);
};

/**
 * publish necessary events
 * @param {Array} changes_ - [channel ("layer"), value (path), 0/1 (close/open)]
 */
__.publishChanges = function (changes_) {
	changes_.forEach(change => {
		const type = (change[2] === RouterState.CLOSE) ? '.close' : '.open';
		const data = {};

		data[change[0]] = change[1];
		const evtName = change[0] + type;

		__.globalEventBus.emit(evtName, {data});
	});
};

/**
 * update browser location hash string and set scrollPos accordingly
 * @param {string} newHashString_ - hash to set
 */
__.updateHash = function (newHashString_) {
	if (newHashString_) {
		__.scrollPos = undefined;
		location.hash = newHashString_;
	}
	else {
		__.scrollPos = document.body.scrollTop;
		location.hash = ' ';
	}
};

/**
 * register handle for click events on selectors
 * the clickHandler takes care for updating the hash
 * @param {string} selector_ - dom selector
 * @param {string} keyName_ - in axs context only 'layer'
 * @param {string} action_ - 'open' or 'close'
 */
__.register = function (selector_, keyName_, action_='open') {
	__.registered.push([selector_, keyName_, action_]);

	dom.getEventDelegate('body').on('click', selector_, __.clickHandler(keyName_, action_));
};

/**
 * creates clickHandler functions from keyName and desired action
 * @param {string} keyName_ - 'layer' in axs context
 * @param {string} action_ - 'open' or 'close'
 * @returns {Function} Click handler callback function
 */
__.clickHandler = (keyName_, action_) => {
	return function(event_) {
		event_.preventDefault();

		let targetPath = this.getAttribute('href');

		if (typeof targetPath === 'undefined') {
			targetPath = event_.target.closest().getAttribute('href');
		}

		__.updateHash(__.newHashString(location.hash, keyName_, targetPath, action_));
	};
};

/**
 * creates RouterState object from given pathname and hash
 * @param {string} pathname_ - pathname part of location string
 * @param {string} hash_ - hash part of location string
 * @returns {RouterState} newly instantiated RouterState object
 */
__.createState = function (pathname_, hash_) {
	return new RouterState(pathname_, hash_);
};

const exports = {
	__,
	register: __.register
};

/**
 * publicly exposed function for closing a hash modifying element (presumable layer)
 * @param {string} keyName_ - 'layer' in axs context
 * @param {string} href_ - the href of the closed layer
 */
exports.close = function (keyName_, href_) {
	__.updateHash(__.newHashString(location.hash, keyName_, href_, 'close'));
};

/**
 * public initialize method
 * @param {EventEmitter2} globalEventBus - Event Bus
 * @returns {Promise} - resolves if initialized
 */
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.globalEventBus = globalEventBus;
		__.initialize();
		resolve('router.js');
	});
};

export {exports as router};
